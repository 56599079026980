<template>
  <div class="add_teacher">
    <el-card class="box-card">
      <el-form ref="addFromRef" :model="addFrom" :rules="addFromRules" label-width="110px">
        <el-form-item label="标题:">
          {{ addFrom.title }}
        </el-form-item>
        <el-form-item label="是否提现:" prop="info">
          <!-- 单选 -->
          <el-radio-group v-model="addFrom.info">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <!-- <div> 外部店长提现设置 0 否 1 是</div> -->
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <!-- <el-button type="primary" @click="goBack">返回</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
    <div class="tables">
      <el-table ref="singleTable" height='250' :data="tableData" highlight-current-row
        @current-change="handleCurrentChange" style="width: 100%">
        <el-table-column type="index" width="50">
        </el-table-column>
        <el-table-column property="masterName" label="操作人">
        </el-table-column>
        <el-table-column property="cur" label="修改前内容">
        </el-table-column>
        <el-table-column property="end" label="修改后内容">
        </el-table-column>
        <el-table-column property="addTime" label="操作时间">
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="Changes" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getBaseSet, outBaseSet, InBaseSet, baseSetForBack } from "../../api/administrator.js";
export default {
  data() {
    return {
      addFrom: {
        info: 0,
        title: "",
        // teacherId: 0,
        // teacherName: "",
        // phone: "",
        // headImgUrl: "",
        // details: "",
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: null,
      baseId: '',
      currentRow: '',

      addFromRules: {
        info: [{ required: true, message: "请选择是否提现", trigger: "blur" }],
      },
      type: ''
    };
  },
  created() {
    this.type = this.$route.query.type
    console.log(this.type, 'this.type');
    this.getBase();
    this.initDate()
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type;
      this.getBase()
      this.initDate()
    },
  },
  methods: {
    getBase() {
      getBaseSet({ id: this.type == 'external' ? 1 : 4 }).then((res) => {
        if (res.data.code == 0) {
          this.addFrom.title = res.data.data.title;
          this.addFrom.info = res.data.data.info;
          this.this.initDate()
        }
      });
    },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;
        this.isSubmit = true;
        let demaed = this.type == 'external' ? outBaseSet : InBaseSet
        const { data } = await demaed({ info: this.addFrom.info });
        this.isSubmit = false;
        if (data.code == 0) {
          //   this.goBack();
          this.getBase();
          this.initDate()
          this.$message.success(data.msg);
        } else {
          this.getBase();
          this.initDate()
          this.$message.warning(data.msg);
        }
      });
    },
    initDate() {
      if (this.type == 'interior') {
        this.baseId = 4
      } else if (this.type == 'external') {
        this.baseId = 1
      }
      console.log(this.pageSize, this.currentPage, 888888);
      baseSetForBack({ baseId: this.baseId, pageSize: this.pageSize, currentPage: this.currentPage }).then((res) => {
        this.tableData = res.data.list
        this.totalItems = res.data.pagination.total
        console.log(99, this.totalItems);
      })
    },
    handleSizeChange(val) {
      this.pageSize = val // 每次修改 pageSize 时，重置到第一页
      this.initDate(); // 重新加载数据
      console.log(`每页 ${val} 条`);
    },
    Changes(val) {
      this.currentPage = val;
      this.initDate()
      console.log(`当前页: ${val}`);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      console.log(val, 777);
    },
    // goBack() {
    //   this.$router.go(-1);
    // },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
}

.box-card {
  padding-bottom: 50px;
}

.tables {
  width: 100%;
  height: 100%;
  position: relative;
}

.block {
  position: absolute;
  right: 20px;
  bottom: -50px;
}
</style>
